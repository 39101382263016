body {
  font-family: "Times New Roman", Times, serif;
}

.arial {
  font-family: Arial, Helvetica, sans-serif;
}

.image-background {
  /* height: 100vh; */
  min-height: -webkit-fill-available;
  /* min-height: fill-available; */
  min-height: 100vh;
  background-image: url("./Images/image\ 1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

html {
  height: -webkit-fill-available;
}

.who-we-are {
  /* height: 100vh; */
  min-height: 350px;
  background-image: url("./Images/Rectangle\ 156.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.linkk {
  text-shadow: 0px 0px 1px;
}

.line-height {
  line-height: 120px !important;
}

.heyy {
  padding-bottom: 50px !important;
}

.owl-item {
  padding-left: 40px;
  width: 350px;
}

.mainButton :hover .arrow {
  rotate: 180deg;
}

.main-class {
  min-height: 340px;
  background-image: url("./Images/Rectangle\ 156.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .main-class {
    min-height: 240px;
    background-image: url("./Images/Rectangle\ 156.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.custom-heading {
  transform: translate(-41px, 17px);
}

.active {
  font-weight: bold;
}

/* Product Slider */
.slider-image {
  max-width: 100%;
  height: auto;
}

.o-slider-image-viewer {
  position: relative;
}

.o-slider-image-viewer__inner {
  display: block;
  margin: 0;
  padding: 0;
}

.o-slider-image-viewer__slide {
  list-style: none;
  text-align: center;
}

.o-slider-image-viewer__nav {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.o-slider-image-viewer__dot {
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 12px;
  margin-left: 4px;
  margin-right: 4px;
  width: 12px;
}

.o-slider-image-viewer__dot:focus {
  outline: 0;
}

.o-slider-image-viewer__dot.tns-nav-active {
  background-color: #aaa;
  cursor: default;
}

.letsTalkButton {
  width: 11% !important;
}

@media screen and (min-width: 900px) {
  .o-slider-image-viewer__dot {
    align-items: center;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid transparent;
    display: flex;
    height: auto;
    justify-content: center;
    padding: 3px;
    width: 48px;
  }

  .o-slider-image-viewer__dot.tns-nav-active {
    background-color: transparent;
    border-color: #aaa;
  }
}

@media screen and (max-width: 899px) {
  .o-slider-image-viewer__dot-image {
    display: none;
  }
}

.c-product-image-viewer__image {
  max-height: calc(90vh - 96px);
}

.slider-heading {
  background-color: white;
  min-height: 180px;
  /* background-image: url("./Images/Rectangle\ 156.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Form Fieldss */

.animation {
  animation-name: slideFromTop;
  animation-duration: 0.6s;
  transform: scale(1);
  opacity: 1;
}

@keyframes slideFromTop {
  0% {
    transform: scale(0);
    /* transition: opacity 0.5s linear; */
  }

  100% {
    transform: scale(1);
  }
}

.animation-remove {
  animation-name: slideFromTopRemove;
  animation-duration: 0.6s;
  transform: scale(0);
  opacity: 1;
}

@keyframes slideFromTopRemove {
  0% {
    transform: scale(1);
    /* transition: opacity 0.5s linear; */
  }

  100% {
    transform: scale(0);
  }
}

._3q7r8 {
  background-color: white !important;
}

._-LJ2W {
  display: flex !important;
  justify-content: center !important;
  margin-top: 30px !important;
}

.swal-title {
  /* font-size: 1.6rem !important; */
  font-family: "Times New Roman", Times, serif;
  color: #132b3d;
  font-size: large !important;
}

.swal-button {
  font-family: "Times New Roman", Times, serif;
  background-color: #132b3d !important;
  color: white;
}

.swal-button:hover {
  background-color: #132b3d !important;
  color: white;
}

.swal-model {
  width: 400px;
}

.swal-icon:first-child {
  margin-top: 15px !important;
}

.backdrop-blurr {
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px) !important;
}

.ResponsibilityClass li {
  list-style-type: disc;
  font-family: arial;
  color: #132b3d;
  font-size: 14px;
  list-style-position: inside;
}

.ResponsibilityClass p {
  display: inline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swal-text {
  text-align: center !important;
}

.st-label {
  display: none !important;
}

.st-btn {
  min-width: 30px !important;
}

.st-last {
  min-width: 30px !important;
  display: inline-block !important;
}

/* #sharethis-1681885242882 .st-btn > img {

} */
/* .sharethis-inline-share-buttons {
  min-width: 300px !important;
  } */

._3lwW_ {
  display: none !important;
}

/*
figure ._t1897 {
  max-width: 500% !important;
}

ul ._t1897 {
  width: 500px !important;
} */

.swal-footer {
  text-align: center;
}
