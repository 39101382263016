@import url("https://fonts.googleapis.com/css2?family=Besley&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.Besley {
  font-family: "Besley", serif;
}

.RedHat {
  font-family: "Red Hat Display", sans-serif;
}

._1BRif::before,
._t1897::before {
  background: red !important;
}
